import { DownloadOutlined } from '@ant-design/icons';
import { Button, Card } from 'antd';
import React, { useState } from 'react';

import RequestServices from '../helpers/requestServices';

const api = new RequestServices({});

export interface IFlyerProps {
  flyers: any[];
  uuid: string;
}

function downloadFile(filePath) {
  const link = document.createElement('a');
  link.href = filePath;
  link.download = filePath.substr(filePath.lastIndexOf('/') + 1);
  link.click();
}

export function Flyer(props: IFlyerProps) {
  const [loading, setLoading] = useState(false);

  const download = async () => {
    setLoading(true);
    const res = await api.downloadMedia(props.uuid, 'flyers', []);
    setLoading(false);
    downloadFile(res.data.data.file);
  };

  const extra = (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Button onClick={download} loading={loading} style={{ marginRight: '10px' }}>
        <DownloadOutlined /> Download
      </Button>
    </div>
  );

  return (
    <Card id="flayer" title="Flyers" style={{ marginBottom: '20px' }} extra={extra}>
      <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr' }}>
        {props.flyers.map((el: any) => {
          return (
            <Card
              key={el.type}
              hoverable={true}
              style={{ width: 270 }}
              cover={<iframe title={`${el.type} flayer iframe`} src={el.file_path} style={{ border: 'none' }} />}
            >
              {el.type}
            </Card>
          );
        })}
      </div>
    </Card>
  );
}
