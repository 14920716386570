import React, { useState } from "react";

import { Form, Input, Button, Alert, Select } from "antd";
import { MailOutlined } from "@ant-design/icons";
// import axios from "axios";
import RequestServices from "../../../helpers/requestServices";

import styles from "../SignIn.module.scss";

import logo from "../../../assets/img/hdProLogoVectorDark.svg";

const FormItem = Form.Item;
const api = new RequestServices({});

export default function ForgotPasswordComponent(email: string) {
  const [showText, setShowText] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [loading, setLoading] = useState(false);
  const [warning, setWarning] = useState(true);
  const [userArr, setUserArr] = useState([]);
  const onFinish = async (e: any) => {
    try {
      setLoading(true);
      const response = await api.forgotPassword(e.email, e.username);
      setShowText(true);
      setLoading(false);
      console.log(response);
      setWarning(e.username ? false : response.data.data.warning);
      setUserArr(response.data.data.accounts);
    } catch (error) {
      setLoading(false);
      setErrorText(error.response.data.errors.detail);
    }
  };

  const onFinishFailed = (e: any) => {};

  const formItemLayout = {
    // labelCol: {
    //   sm: { span: 8 },
    // },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
    },
  };

  return (
    <div className={styles.Wrapper}>
      <div style={{ width: 400, transform: "translateY(-25%)" }}>
        <div className={styles.Logo}>
          <img src={logo} alt="Logo" />
        </div>
        {!showText || warning ? (
          <div>
            <h1 className="page-title">Forgot Password?</h1>
            <p>
              Enter the email associated with your account, then click continue.
              We’ll send you a reset link where you can update your password.
            </p>
            {errorText && (
              <Alert
                message={errorText}
                type="error"
                showIcon
                closable={true}
                style={{ marginBottom: 10 }}
                afterClose={() => setErrorText("")}
              />
            )}
            <Form
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              className="login-form"
              name="normal_login"
              initialValues={{ remember: true }}
            >
              <div className="inputs-wrapper">
                <FormItem
                  // label="Password"
                  name="email"
                  rules={[
                    { required: true, message: "Please input your email" },
                  ]}
                  {...formItemLayout}
                >
                  <Input
                    size="large"
                    placeholder="Email or Username"
                    prefix={<MailOutlined className="site-form-item-icon" />}
                  />
                </FormItem>
                {!!userArr.length && (
                  <div>
                    <span>There are a few accounts with your email</span>
                    <Form.Item name="username">
                      <Select size="large" placeholder="Select username">
                        {userArr.map((el) => {
                          return <Select.Option value={el}>{el}</Select.Option>;
                        })}
                      </Select>
                    </Form.Item>
                  </div>
                )}
              </div>
              <FormItem {...formItemLayout} style={{ marginBottom: 10 }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  size="large"
                  loading={loading}
                  // className="btn btn--action"
                  style={{ width: "100%" }}
                >
                  Continue
                </Button>
              </FormItem>
            </Form>
          </div>
        ) : (
          <p style={{ textAlign: "center" }}>
            A password reset link has been sent to the email on file. If you
            have trouble, please contact{" "}
            <a href="https://floorplanonline.com/support/">Customer Care</a>
          </p>
        )}
      </div>
    </div>
  );
}
