import { DownloadOutlined } from '@ant-design/icons';
import { Button, Card } from 'antd';
import * as React from 'react';

import RequestServices from '../helpers/requestServices';
const api = new RequestServices({});
export interface ICall2ActionProps {
  uuid: string;
  call2Action: any[];
}


function downloadFile(filePath) {
  const link = document.createElement('a');
  link.href = filePath;
  link.download = filePath.substr(filePath.lastIndexOf('/') + 1);
  link.click();
}

export function Call2Action(props: ICall2ActionProps) {
  const [loading, setLoading] = React.useState(false);
  const download = async () => {
    setLoading(true);
    const res = await api.downloadMedia(props.uuid, 'call2action', []);
    setLoading(false);
    downloadFile(res.data.data.file);
  };
  const extra = (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Button
        loading={loading}
        icon={<DownloadOutlined />}
        onClick={download}
        style={{ marginRight: '10px' }}
      >
        Download
      </Button>
    </div>
  );
  return (
    <Card id="call2Action" title="Call2Action" style={{ marginBottom: '20px' }} extra={extra}>
      <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr' }}>
        {props.call2Action.map((el: any) => {
          return (
            <Card
              key={el.title}
              // hoverable={true}
              style={{ width: 270 }}
              // cover={<img src={el.image_url} />}
            >
              <div>
                <img src={el.photo} alt={el.title}/>
              </div>
              {el.title}
            </Card>
          );
        })}
      </div>
    </Card>
  );
}
