import { Button, Card } from 'antd';
import React, { useState } from 'react';
import IframeModal from '../helpers/Components/IframeModal';
import { DownloadOutlined } from '@ant-design/icons';

export interface ICustomVideoProps {
  uuid: string;
  customVideo: any[];
}

export function CustomVideo(props: ICustomVideoProps) {
  const [viewModal, setViewModal] = useState({
    visible: false,
    url: '',
    name: '',
  });

  return (
    <Card id="customVideo" title="Custom Video" style={{ marginBottom: '20px' }}>
      <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr' }}>
        {props.customVideo.map((el: any, i: number) => {
          const url =
            el.type === 'youtube'
              ? `https://www.youtube.com/embed/${el.video}?autoplay=0`
              : `https://player.vimeo.com/video/${el.video}`;
          return (
            <Card
              key={el.video}
              // hoverable={true}
              style={{ width: 270 }}
              cover={
                <div style={{ position: 'relative' }}>
                  <iframe
                    title={`Iframe with ${el.title} video`}
                    src={url}
                    style={{ width: '100%', border: 'none', padding: 10 }}
                  />
                  <div
                    onClick={(e) => {
                      setViewModal({
                        visible: true,
                        url,
                        name: el.title,
                      });
                    }}
                    style={{
                      position: 'absolute',
                      width: '100%',
                      height: '100%',
                      top: 0,
                      left: 0,
                      cursor: 'pointer',
                    }}
                  />
                </div>
              }
            >
              <div style={{display: 'flex', flexDirection: 'column'}}>
                <span style={{marginBottom: 10}}>{el.title}</span>
                <span>
                  {el.type === 'vimeo' && <a target='_blank' href={`${el.download}`}><DownloadOutlined /> Download</a>}
                </span>
              </div>
            </Card>
          );
        })}
      </div>
      <IframeModal
        changeVisible={() => setViewModal({ ...viewModal, visible: false })}
        isModalVisible={viewModal.visible}
        iframeLink={viewModal.url}
      />
    </Card>
  );
}
