export const GET_INPUT_VALUE = 'GET_INPUT_VALUE';
export type GET_INPUT_VALUE = typeof GET_INPUT_VALUE;

export const CHANGE_INPUT_VALUE = 'CHANGE_INPUT_VALUE';
export type CHANGE_INPUT_VALUE = typeof CHANGE_INPUT_VALUE;

export const ON_BLUR_INPUT = 'ON_BLUR_INPUT';
export type ON_BLUR_INPUT = typeof ON_BLUR_INPUT;

export const GET_PREVIEW_SRC = 'GET_PREVIEW_SRC';
export type GET_PREVIEW_SRC = typeof GET_PREVIEW_SRC

export const CHANGE_MODAL_VISIBLE = 'CHANGE_MODAL_VISIBLE';
export type CHANGE_MODAL_VISIBLE = typeof CHANGE_MODAL_VISIBLE

export const GET_WINDERMERE_USER = 'GET_WINDERMERE_USER';
export type GET_WINDERMERE_USER = typeof GET_WINDERMERE_USER



