export const RESET_NEW_ORDER_DATA = "RESET_NEW_ORDER_DATA";
export const SET_ADDRESS_DATA = "SET_ADDRESS_DATA";
export const SET_OWNER_DATA = "SET_OWNER_DATA";
export const SET_PACKAGE_DATA = "SET_PACKAGE_DATA";
export const CLEAR_PACKAGE_DATA = "CLEAR_PACKAGE_DATA";
export const SET_UUID = "SET_UUID";
export const SET_HD_PROPERTY_ID = "SET_HD_PROPERTY_ID";
export const SET_FORM = "SET_FORM";
export const SET_PLANS = "SET_PLANS";
export const SET_HD_ORDER_DATA = "SET_HD_ORDER_DATA";
