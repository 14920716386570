import React, { Component } from "react";
import { Form, Input, Button, Checkbox, Alert } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import logo from "../../assets/img/hdProLogoVectorDark.svg";
import styles from "./SignIn.module.scss";

import RequestServies from "../../helpers/requestServices";

const api = new RequestServies({});

export interface IAppProps {
  form: any;
}

export interface IAppState {}

class SignInComponent extends Component<IAppProps, IAppState> {
  state = {
    validateStatus: {
      username: "",
    },
    errorMessage: "",
    loading: false,
    subLogo: "",
    waitLogo: true,
  };

  componentDidMount() {
    this.getLogo();
    document.title = 'Sign In - HD Pro'
  }

  getLogo = async () => {
    try {
      const data: any = await api.getLogo();
      if (!data) {
        this.setState({
          waitLogo: false,
        });
        return;
      }
      this.setState({
        subLogo: data.data.data.logo,
        waitLogo: false,
      });
    } catch (error) {
      this.setState({
        waitLogo: false,
      });
    }
  };

  handleSubmit = async (e: any) => {
    this.setState({
      loading: true,
    });
    const data: any = await api.loginUser(e.username, e.password);
    this.setState({
      loading: false,
    });
    if (data && data.type === "error") {
      this.setState({
        errorMessage: data.error.response.data.errors.detail,
      });
    }

    if (data && data.data.data.status === "Success") {
      if (data.data.data.accountType === "agent"){
        const href = window.localStorage.getItem('href');
        window.location.href = href ? href : window.location.origin + '/property';
        window.localStorage.removeItem('href')
      } else {
        window.location.href = process.env.REACT_APP_API_URL as any;
      }
    }
  };

  onFinishFailed = (errorInfo: any) => {
    errorInfo.errorFields.forEach((element: any) => {
      this.setState({
        validateStatus: {
          ...this.state.validateStatus,
          [element.name]: element.errors[0],
        },
      });
    });
  };

  clearError = (name: string) => {
    this.setState({
      validateStatus: {
        ...this.state.validateStatus,
        [name]: "",
      },
    });
  };

  clearErrorMessage = () => {
    this.setState({
      errorMessage: "",
    });
  };

  public render() {
    const FormItem = Form.Item;
    const { username } = this.state.validateStatus;
    const { errorMessage, subLogo } = this.state;
    // const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      // labelCol: {
      //   sm: { span: 8 },
      // },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
      },
    };
    if (this.state.waitLogo) {
      return null;
    }
    return (
      <div className={styles.Wrapper}>
        <div style={subLogo ? {width: 400} : { width: 400, transform: "translateY(-25%)" }}>
          {subLogo && [
            <div className={styles.SubLogo}>
              <img src={subLogo} alt="Sub logo" />
            </div>,
            <span className={styles.Powered}>
              powered by
            </span>
          ]}
          <div className={styles.Logo} style={subLogo ? {width: 200}: {}}>
            <img src={logo} alt="Logo" />
          </div>
          {errorMessage && (
            <Alert
              message={errorMessage}
              type="error"
              showIcon
              closable={true}
              afterClose={this.clearErrorMessage}
            />
          )}
          <h1 className="page-title">Sign In</h1>
          <Form
            onFinish={this.handleSubmit}
            onFinishFailed={this.onFinishFailed}
            className="login-form"
            name="normal_login"
            initialValues={{ remember: true }}
          >
            <div className="inputs-wrapper">
              <FormItem
                // label="Username"
                help="Typically your email address."
                name="username"
                style={{ marginBottom: 10 }}
                validateStatus={username && "error"}
                rules={[
                  { required: true, message: "Please input your username" },
                ]}
                {...formItemLayout}
              >
                <Input
                  placeholder="Username"
                  size="large"
                  prefix={<UserOutlined className="site-form-item-icon" />}
                  onFocus={() => this.clearError("username")}
                />
              </FormItem>
              <FormItem
                // label="Password"
                name="password"
                rules={[
                  { required: true, message: "Please input your password" },
                ]}
                {...formItemLayout}
              >
                <Input.Password
                  type="password"
                  size="large"
                  placeholder="Password"
                  prefix={<LockOutlined className="site-form-item-icon" />}
                />
              </FormItem>
            </div>
            <Form.Item>
              <a
                className="login-form-forgot"
                style={{ float: "right" }}
                href=""
              >
                <Link to="/forgot">Forgot password?</Link>
              </a>
            </Form.Item>
            <FormItem {...formItemLayout} style={{ marginBottom: 10 }}>
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                loading={this.state.loading}
                // className="btn btn--action"
                style={{ width: "100%" }}
              >
                Sign In
              </Button>
            </FormItem>
            <div>
              <span style={{ float: "right" }}>
                Not a member? <Link to={`/registration${window.location.search}`}>Sign Up</Link>
              </span>
            </div>
          </Form>
        </div>
        {/* <div className={styles.SignInForm}>
          <div className="form-header">
            <h2>Sign In</h2>
          </div>
          <hr />
        </div> */}
      </div>
    );
  }
}
export default SignInComponent;
