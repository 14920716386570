import { Card } from 'antd';
import React from 'react';
import { DownloadOutlined, FilePdfOutlined } from '@ant-design/icons';

export interface IDocumentsProps {
  documents: any[];
  uuid: string;
}

export function Documents(props: IDocumentsProps) {
  return (
    <Card
      id="documents"
      title="Documents"
      style={{ marginBottom: '20px' }}
      //   extra={<AddToPresentationComponent />}
    >
      <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr' }}>
        {props.documents.map((el: any) => {
          return (
            <Card
              key={el.type}
              style={{ width: 270, marginBottom: 20 }}
              actions={[
                <a href={el.file_path} rel="noreferrer" target="_blank" download={true}>
                  <DownloadOutlined key="download" />
                </a>,
              ]}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  padding: 15,
                }}
              >
                <FilePdfOutlined style={{ fontSize: 72, marginBottom: 10 }} />
                <span style={{ marginBottom: 10 }}>{el.type}</span>
                <span style={{ marginBottom: 10, opacity: 0.45 }}>.pdf</span>
              </div>
            </Card>
          );
        })}
      </div>
    </Card>
  );
}
