import { getOrdersList } from './actions';
import {
  GET_ORDERS_LIST_FULFILLED,
  GET_ORDERS_LIST_PENDING,
  GET_ORDERS_LIST_REJECTED,
  UPDATE_ORDER,
  GET_ORDERS_STATS,
  GET_CANCEL_REASONS,
  GET_TYPES_TO_STATUSES,
} from './constans';

interface IState {
  orders: any[];
  pending: boolean;
  totalCount: number;
  ordersStats: any;
}

const initialState = {
  orders: [],
  pending: true,
  totalCount: 0,
  ordersStats: {
    visits: 0,
    action: 0,
  },
  cancelReasons: [],
  typesToStatusesArray: []
};

export function ordersReducer(state: IState = initialState, action: any) {
  switch (action.type) {
    case GET_ORDERS_LIST_PENDING:
      return {
        ...state,
        pending: true,
      };
    case GET_ORDERS_LIST_FULFILLED:
      return {
        ...state,
        orders: action.payload.orders,
        totalCount: action.payload.totalCount,
        pending: false,
      };
    case GET_ORDERS_LIST_REJECTED:
      return {
        ...state,
        pending: false,
      };
    case UPDATE_ORDER:
      return {
        ...state,
        orders: action.payload,
      };
    case GET_ORDERS_STATS:
      return {
        ...state,
        ordersStats: action.payload,
      };
    case GET_CANCEL_REASONS:
      return {
        ...state,
        cancelReasons: action.payload,
      };
    case GET_TYPES_TO_STATUSES:
      return {
        ...state,
        typesToStatusesArray: action.payload
      }
    default:
      break;
  }
  return state;
}
