import * as React from "react";
import { Menu, Affix } from "antd";

import styles from "./Details.module.scss";
import {useState} from "react";

export interface IMenuComponentProps {
  items: any[]
}

export default function MenuComponent(props: IMenuComponentProps) {
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);

  window.addEventListener('resize', () => {
    setWindowWidth(window.innerWidth)
  });

  React.useEffect(() => {
    getItemHeight();
  }, []);

  // const [heightItem, setHeightItem] = React.useState({}) as any;
  const [active, setActive] = React.useState('listing')

  const onClick = (e: any) => {
    const el: any = document.getElementById(e.key);
    el.scrollIntoView({ block: "start", behavior: "smooth" });
  };

  const getItemHeight = () => {
    const idArray = [
      "listing",
      "address",
      "propertyFacts",
      "rooms",
      "description",
      "features",
    ];
    let heightItem: any = {};
    props.items.forEach((item: any) => {
      const element = document.getElementById(item.key) || { offsetTop: 0 };
      heightItem = {
        ...heightItem,
        [element.offsetTop]: item.key,
      };
    });

    window.addEventListener('scroll', (e) => {
      Object.keys(heightItem).forEach((e: any) => {
        if (window.scrollY < e && window.scrollY > e - 100) {
          setActive(heightItem[e])
        }
        // (window.scrollY - 50 > e || window.scrollY + 50 < e) && setActive(heightItem[e])
      })

      // heightItem[window.scrollY] && setActive(heightItem[window.scrollY])
    })
  };

  return (
    <div className={styles.MenuWrapper}>
      <Affix offsetTop={windowWidth < 1160 ? 0 : 10}>
        <Menu
          onClick={onClick}
          mode={windowWidth < 1160 ? "horizontal" : "inline"}
          defaultSelectedKeys={[props.items[0].key]}
          selectedKeys={[active]}
        >
          {props.items.map((item: any) => {
            return <Menu.Item className={styles.Item} key={item.key}>
            {item.title}
          </Menu.Item>
          })}
        </Menu>
      </Affix>
    </div>
  );
}
