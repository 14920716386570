import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Form, Input, Button, Alert } from "antd";
import { MailOutlined } from "@ant-design/icons";
// import axios from "axios";
import RequestServices from "../../../helpers/requestServices";

import styles from "../SignIn.module.scss";

import logo from "../../../assets/img/hdProLogoVectorDark.svg";

const FormItem = Form.Item;
const api = new RequestServices({});

export default function ResetPasswordComponent() {
  const [showText, setShowText] = useState(false);
  const [loadingValid, setLoadingValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [valid, setValid] = useState(true);
  useEffect(() => {
    validCode();
  }, []);
  const onFinish = async (e: any) => {
    try {
      setLoading(true);
      const response = await api.resetPassword(e.password, e.confirmPassword);
      setShowText(true);
      setLoading(false);
    } catch (error) {
      setErrorText(error.response.data.errors.detail);
      setLoading(false);
    }
  };

  const validCode = async () => {
    setLoadingValid(true);
    try {
      await api.validateUnique(
        "reset_code",
        window.location.pathname.split("/")[3]
      );
      setLoadingValid(false);
    } catch (error) {
      setLoadingValid(false);
      setValid(false);
    }
  };

  const onFinishFailed = (e: any) => {};

  const formItemLayout = {
    // labelCol: {
    //   sm: { span: 8 },
    // },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
    },
  };

  if (loadingValid) {
    return null;
  }

  if (!valid) {
    return (
      <div className={styles.Wrapper}>
        <div style={{ width: 400, transform: "translateY(-25%)" }}>
          <div className={styles.Logo}>
            <img src={logo} alt="Logo" />
          </div>
          <p style={{ textAlign: "center" }}>Confirm code is invalid</p>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.Wrapper}>
      <div style={{ width: 400, transform: "translateY(-25%)" }}>
        <div className={styles.Logo}>
          <img src={logo} alt="Logo" />
        </div>
        {!showText ? (
          <div>
            <h1 className="page-title">Reset Password</h1>
            <Form
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              className="login-form"
              name="normal_login"
              initialValues={{ remember: true }}
            >
              <div className="inputs-wrapper">
                {errorText && (
                  <Alert
                    message={errorText}
                    type="error"
                    showIcon
                    closable={true}
                    style={{ marginBottom: 10 }}
                    afterClose={() => setErrorText("")}
                  />
                )}
                <FormItem
                  // label="Password"
                  name="password"
                  rules={[
                    { required: true, message: "Password is required" },
                    { min: 6, message: "Case-sensitive, min. 6 characters" },
                  ]}
                  {...formItemLayout}
                >
                  <Input.Password
                    size="large"
                    placeholder="New password"
                    // prefix={<MailOutlined className="site-form-item-icon" />}
                  />
                </FormItem>
                <FormItem
                  // label="Password"
                  name="confirmPassword"
                  dependencies={["password"]}
                  rules={[
                    { required: true, message: "Confirm Password is required" },
                    { min: 6, message: "Case-sensitive, min. 6 characters" },
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject("Passwords do not match");
                      },
                    }),
                  ]}
                  {...formItemLayout}
                >
                  <Input.Password
                    size="large"
                    placeholder="Confim new password"
                    // prefix={<MailOutlined className="site-form-item-icon" />}
                  />
                </FormItem>
              </div>
              <FormItem {...formItemLayout} style={{ marginBottom: 10 }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  size="large"
                  loading={loading}
                  // className="btn btn--action"
                  style={{ width: "100%" }}
                >
                  Reset Password
                </Button>
              </FormItem>
            </Form>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <p style={{ textAlign: "center" }}>
              Your password has been reset successfully.
            </p>
            <Link to="/signin">
              <Button style={{ width: 265 }} type="primary">
                Sign In
              </Button>
            </Link>
          </div>
        )}
      </div>
    </div>
  );
}
