import { CHANGE_ACCOUNTS_ID } from "./constans";

interface IState {
    id: number | undefined
}

const initialState = {
    id: undefined
}


export function MainReducer(state: IState = initialState, action: any) {
    switch (action.type) {
        case CHANGE_ACCOUNTS_ID:
            return {
                id: action.payload
            }
        default:
            break;
    }
    return state
}