import React, { useState } from 'react';
import {
  CloseOutlined,
  DownloadOutlined,
  DownOutlined,
  LeftOutlined,
  LinkOutlined,
  RightOutlined,
  UpOutlined,
} from '@ant-design/icons';
import { Card, Button, Modal, Carousel } from 'antd';

import styles from '../Story/Media/Media.module.scss';

import { DownloadPhotos } from '../Story/Media/DownloadPhotos';

// const getBase64 = (file: any) => {
//   return new Promise((resolve, reject) => {
//     const reader = new FileReader();
//     reader.readAsDataURL(file);
//     reader.onload = () => resolve(reader.result);
//     reader.onerror = (error) => reject(error);
//   });
// };

const customCarouselButtonsStyle = {
  display: 'flex',
  margin: '0 12px',
  zIndex: 100,
  alignItems: 'center',
  width: 140,
};

export interface IPhotoProps {
  photo: any[];
  uuid: string;
  id: number;
}

const isDownload = window.location.search === '?download';

export default function Photo(props: IPhotoProps) {
  const [collapse, setCollapse] = useState(true);
  const [modalVisible, setModalVisible] = useState(isDownload);
  const [visiblePhotos, setVisiblePhotos] = useState<any[] | undefined>(undefined);
  const seeAll = props.photo.length > 9;
  const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          ...customCarouselButtonsStyle,
          justifyContent: 'flex-end',
          top: 30,
          height: 'calc(100% - 30px)',
        }}
        onClick={onClick}
      >
        <Button icon={<RightOutlined />} />
      </div>
    );
  };

  const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          ...customCarouselButtonsStyle,
          justifyContent: 'flex-start',
          top: 0,
          height: '100%',
        }}
        onClick={onClick}
      >
        <Button icon={<LeftOutlined />} />
      </div>
    );
  };

  const settings = {
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  const deleteOverlay = (imageId: number, index: number) => {
    return (
      <div
        onClick={() => {
          // setImage(imageId);
          const carouselPhotos = [...props.photo.slice(index), ...props.photo.slice(0, index)];
          setVisiblePhotos(carouselPhotos);
        }}
      >
        {/* <DeleteOutlined style={{ fontSize: 32, color: '#fff' }} /> */}
      </div>
    );
  };
  const extra = (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {/* <Button style={{ margin: "0 10px" }}>
        <ShareAltOutlined /> Share
      </Button> */}
      <a
        style={{ marginRight: '10px' }}
        target="_blank"
        href={
          process.env.REACT_APP_API_URL === 'https://dev.vifp.com'
            ? `https://my-dev.homediary.com/fam/gallery/${props.id}`
            : `https://my.homediary.com/fam/gallery/${props.id}`
        }
      >
        <Button type="dashed">
          <LinkOutlined /> View Gallery
        </Button>
      </a>
      <Button
        onClick={() => setModalVisible(true)}
        style={{ marginRight: '10px' }}
        icon={<DownloadOutlined />}
      >
        Download
      </Button>
      <DownloadPhotos
        uuid={props.uuid}
        setModalVisible={setModalVisible}
        isDownloadPhotosVisible={modalVisible}
      />
    </div>
  );

  return (
    <Card id="photo" title="Photo" style={{ marginBottom: '20px' }} extra={extra}>
      <Modal
        footer={null}
        title={null}
        className={styles.CarouselModal}
        closeIcon={<Button icon={<CloseOutlined />} />}
        visible={!!visiblePhotos}
        onCancel={() => setVisiblePhotos(undefined)}
        destroyOnClose={true}
      >
        <Carousel swipeToSlide draggable arrows {...settings} effect="fade" dots={false}>
          {visiblePhotos &&
            visiblePhotos.map((el: any) => {
              return (
                <div key={el.id}>
                  <img className={styles.CarouselImage} src={el.url} alt="" />
                </div>
              );
            })}
          {/* {uploadFiles} */}
        </Carousel>
      </Modal>
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          height: collapse || !props.photo.length ? 255 : '100%',
          overflow: 'hidden',
          margin: '0 -2px',
        }}
      >
        {props.photo.map((el: any, i: number) => {
          return (
            <div className={styles.PhotoWrapper} key={el.id}>
              <img src={`${el.url}?w=400`} alt="" />
              {deleteOverlay(el.url, i)}
            </div>
          );
        })}
      </div>
      {seeAll && (
        <div style={{ marginTop: 10, float: 'right' }}>
          <Button onClick={() => setCollapse(!collapse)} type="link">
            {!collapse ? <UpOutlined /> : <DownOutlined />} See all
          </Button>
        </div>
      )}
    </Card>
  );
}
