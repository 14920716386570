import { Card } from 'antd';
import React, { useState } from 'react';
import IframeModal from '../helpers/Components/IframeModal';
interface IMatterportProps {
  matterport: any[];
}

export function Matterport(props: IMatterportProps) {
  const [viewModal, setViewModal] = useState({
    visible: false,
    url: '',
    name: '',
  });
  const { matterport } = props;

  return (
    <Card id="360walk" title="3D Walkthrough" style={{ marginBottom: '20px' }}>
      <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr' }}>
        {matterport.map((el: any) => {
          const matterportSource = `https://my.matterport.com/show/?m=${el.video}&play=0&wh=0&ts=2&guides=0`;
          return (
            <Card
              key={el.title}
              hoverable={true}
              style={{ width: 270 }}
              cover={
                <div style={{ position: 'relative' }}>
                  <iframe
                    title={`${el.title} matterport`}
                    src={matterportSource}
                    style={{ border: 'none', width: '100%' }}
                  />
                  <div
                    onClick={(e) => {
                      setViewModal({
                        visible: true,
                        url: matterportSource,
                        name: el.title,
                      });
                    }}
                    style={{
                      position: 'absolute',
                      width: '100%',
                      height: '100%',
                      top: 0,
                      left: 0,
                      cursor: 'pointer',
                    }}
                  />
                </div>
              }
            >
              {el.title}
            </Card>
          );
        })}
      </div>
      <IframeModal
        changeVisible={() => setViewModal({ ...viewModal, visible: false })}
        isModalVisible={viewModal.visible}
        iframeLink={viewModal.url}
      />
    </Card>
  );
}
