import {
  CopyOutlined,
  DesktopOutlined,
  QrcodeOutlined,
} from "@ant-design/icons";
import { Button, Card, List, message } from "antd";
import React, { useState } from "react";
import RequestServices from "../../helpers/requestServices";
import { MESSAGE_POSITION } from "../../constants/message";
import styles from "./Media.module.scss";

function downloadFile(filePath) {
  const link = document.createElement("a");
  link.href = filePath;
  link.target = '_blank'
  link.download = filePath.substr(filePath.lastIndexOf("/") + 1);
  link.click();
}
export interface ILinksProps {
  links: any[];
  uuid: string;
}

const api = new RequestServices({});

export default function Links(props: ILinksProps) {
  const [loading, setLoading] = useState(false);
  const getQRCode = async () => {
    setLoading(true);
    const { data } = await api.getQRCode(props.uuid);
    console.log(data);
    window.open(data.data.qr_code_url, '_blank');
    setLoading(false);
  };

  const onCopyLink = (link: string) => {
    navigator.clipboard.writeText(link);
    message.success({
      content: "Copied to clipboard",
      style: MESSAGE_POSITION,
    });
  }

  const links = props.links.map((el: any) => {
    return {
      type: el.type,
      link: el.link,
      icon: <DesktopOutlined />,
      description: el.about,
    };
  });
  return (
    <div id="links" style={{ marginBottom: "20px" }}>
      <Card title="Presentation Links">
        <List
          itemLayout="horizontal"
          dataSource={links}
          renderItem={(item) => (
            <List.Item className={styles.LinkItem}>
              <List.Item.Meta
                avatar={item.icon}
                title={item.type}
                
                description={
                  <a href={item.link} style={{textOverflow: 'ellipsis',
                  overflow: 'hidden', display: 'block'}} target="_blank">
                    {item.link}
                  </a>
                }
              />
              <div className="contentWrapper">
                <div className="content" >
                  <div>{item.description}</div>
                  <div
                    style={{
                      display: "flex",
                      flexGrow: 1,
                      justifyContent: "flex-end",
                    }}
                  >
                    {item.type === "Branded" && (
                      <div style={{ marginRight: 10 }}>
                        <Button
                          loading={loading}
                          icon={<QrcodeOutlined />}
                          onClick={getQRCode}
                          size="small"
                          type="dashed"
                        >
                          Get QR code
                        </Button>
                      </div>
                    )}
                    <div>
                      <Button
                        size="small"
                        onClick={() => onCopyLink(item.link)}
                        type="dashed"
                      >
                        <CopyOutlined /> Copy link
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </List.Item>
          )}
        />
      </Card>
    </div>
  );
}
