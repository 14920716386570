export const GET_ORDERS_LIST_PENDING = 'GET_ORDERS_LIST_PENDING';
export type GET_ORDERS_LIST_PENDING = typeof GET_ORDERS_LIST_PENDING;

export const GET_ORDERS_LIST_FULFILLED = 'GET_ORDERS_LIST_FULFILLED';
export type GET_ORDERS_LIST_FULFILLED = typeof GET_ORDERS_LIST_FULFILLED;

export const GET_ORDERS_LIST_REJECTED = 'GET_ORDERS_LIST_REJECTED';
export type GET_ORDERS_LIST_REJECTED = typeof GET_ORDERS_LIST_REJECTED

export const UPDATE_ORDER = 'UPDATE_ORDER';
export type UPDATE_ORDER = typeof UPDATE_ORDER


export const GET_ORDERS_STATS = 'GET_ORDERS_STATS';
export type GET_ORDERS_STATS = typeof GET_ORDERS_STATS

export const GET_CANCEL_REASONS = 'GET_CANCEL_REASONS';
export type GET_CANCEL_REASONS = typeof GET_CANCEL_REASONS

export const GET_TYPES_TO_STATUSES = 'GET_TYPES_TO_STATUSES';
export type GET_TYPES_TO_STATUSES = typeof GET_TYPES_TO_STATUSES