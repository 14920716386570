import { Modal, Form, Radio, Input, Checkbox, Space, Button, Row, Col } from 'antd';
import React, { useState, useRef } from 'react';

import RequestServices from '../../helpers/requestServices';

const api = new RequestServices({});

export interface IDownloadPhotosProps {
  isDownloadPhotosVisible: boolean;
  uuid: string;
  setModalVisible: (value: boolean) => void;
}

function downloadFile(filePath) {
  const link = document.createElement('a');
  link.href = filePath;
  link.download = filePath.substr(filePath.lastIndexOf('/') + 1);
  link.click();
}

const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 10 },
};

export function DownloadPhotos(props: IDownloadPhotosProps) {
  const [loading, setLoading] = useState(false);
  const [custom, setCustom] = useState(false);
  const formRef = useRef() as any;
  const { isDownloadPhotosVisible } = props;
  const onFinish = async (value: any) => {
    console.log(value);
    setLoading(true);

    const data = value.size.map((el: any, i: number) => {
      if (el === 'custom') {
        return {
          size: 'custom',
          width: value.width,
          height: value.height,
          crop: !value.crop,
        };
      }
      return {
        size: value.size[i],
      };
    });

    console.log(data);
    // value.size === "custom"
    //   ? {
    //       size: "custom",
    //       width: value.width,
    //       height: value.height,
    //       crop: !value.crop,
    //     }
    //   : {
    //       value,
    //     };
    const res = await api.downloadMedia(props.uuid, 'images', data);
    setLoading(false);
    props.setModalVisible(false);
    downloadFile(res.data.data.file);
    console.log(res.data.data.file);
  };

  const footer = (
    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
      <div>
        <Button onClick={() => props.setModalVisible(false)} type="default">
          Cancel
        </Button>
        <Button type="primary" loading={loading} onClick={() => formRef.current.submit()}>
          Download
        </Button>
      </div>
    </div>
  );
  return (
    <div>
      <Modal
        title="Download Photos"
        visible={isDownloadPhotosVisible}
        width={800}
        onCancel={() => props.setModalVisible(false)}
        footer={footer}
      >
        <Form
          initialValues={{ size: ['print'], crop: true }}
          {...formItemLayout}
          ref={formRef}
          onFinish={onFinish}
        >
          <Form.Item name="size" label="Sizes">
            {/* <Radio.Group
              onChange={(e: any) => {
                e.target.value === "custom"
                  ? setCustom(true)
                  : setCustom(false);
              }}
            >
              <Space direction="vertical">
                <Radio value="web">MLS/Web - Medium (1024 pixels)</Radio>
                <Radio value="fhd">MLS/Web - Large (1920 pixels)</Radio>
                <Radio value="print">Print Quality (~8.5”x11”)</Radio>
                <Radio value="custom">Custom Size</Radio>
              </Space>
            </Radio.Group> */}
            <Checkbox.Group
              style={{ width: '100%' }}
              onChange={(e) => (e.includes('custom') ? setCustom(true) : setCustom(false))}
            >
              <Row>
                <Col span={24}>
                  <Checkbox value="web">Medium (1024 pixels)</Checkbox>
                </Col>
                <Col span={24}>
                  <Checkbox value="fhd">Large (2500 pixels)</Checkbox>
                </Col>
                <Col span={24}>
                  <Checkbox value="print">Print Quality (4000px / ~8.5”x11”)</Checkbox>
                </Col>
                <Col span={24}>
                  <Checkbox value="custom">Custom Size</Checkbox>
                </Col>
              </Row>
            </Checkbox.Group>
          </Form.Item>
          {custom && (
            <div>
              <Form.Item label="Width" style={{ margin: 0 }}>
                <div style={{ display: 'flex' }}>
                  <Form.Item name="width" style={{ marginRight: 10 }}>
                    <Input />
                  </Form.Item>
                  <Form.Item name="height" label="Height">
                    <Input />
                  </Form.Item>
                </div>
              </Form.Item>
              <Form.Item valuePropName="checked" wrapperCol={{ offset: 8, span: 10 }} name="crop">
                <Checkbox defaultChecked={true}>Constrain proportions</Checkbox>
              </Form.Item>
            </div>
          )}
          <p style={{width: 595, opacity: .45, margin: '0 auto'}}>
            <div style={{marginBottom: 20}}>
              NOTE: “print quality” is the original, un-resized version which will work for 8.5x11
              inch prints and will work for almost any web use (social media, MLS systems, etc.).
            </div>
            <div>
              Most MLSs now automatically resize to fit their needs. Use “custom” if you have a very
              specific need. You can create a custom crop by specifying both height and width
              (example: crop square or portrait for social media).
            </div>
          </p>
        </Form>
      </Modal>
    </div>
  );
}
