import axios from 'axios';
import { message } from 'antd';
const qs = require('qs');

const postData = {
  client_id: 'FloorplaOnline Rest API',
  client_secret: process.env.REACT_APP_NOT_CLIENT_SECRET,
  grant_type: 'client_credentials',
};

const headers = {
  'Content-Type': 'application/x-www-form-urlencoded',
};

const apiUrl = process.env.REACT_APP_API_URL;

const AUTHORIZE = '/api/authorize';
const VALIDATION = '/api/v1/actions/validation';
const ACCOUNTS = '/api/accounts';
const ORDERS = '/api/v1/orders';
const FORGOT_PASSWORD = '/api/v1/actions/forgot-password';
const HOTSPOTS = '/hotspots';
const LISTING_STATUS = '/api/v1/helpers/listing-status';
const PARENT = '/api/v1/actions/parent';
const PAYMENTS = '/api/v1/payments';

const AUTH_OLD = '/api/v1/actions/auth/login';
const CHECK_MLS = '/api/checkmls';

const STATES = '/api/v1/helpers/states?country_id=1';

const accountsStorage = window.localStorage;
let token = accountsStorage.getItem('token');
let refreshToken = accountsStorage.getItem('refreshToken');
let uuid = accountsStorage.getItem('uuid');

export default class RequestServies {
  client: any;
  token: string | null;
  refreshToken: string | null;
  refreshRequest: any;
  constructor(options: any) {
    this.client = axios.create();
    this.token = token;
    this.refreshToken = refreshToken;
    this.refreshRequest = null;

    this.client.interceptors.request.use(
      (config: any) => {
        if (!token) {
          return config;
        }

        const newConfig = {
          headers: {},
          ...config,
        };

        newConfig.headers.Authorization = `Bearer ${token}`;
        return newConfig;
      },
      (e: any) => Promise.reject(e),
    );

    this.client.interceptors.response.use(
      (r: any) => r,
      async (error: { response: { status: number; data: any }; config: { retry: any } }) => {
        if (error.response.status === 401) {
          window.localStorage.setItem('href', window.location.href);
          message.info({
            content: 'Your Session Expired',
            duration: 1,
            onClose: () => {
              window.location.href = window.location.origin + '/signin';
            },
          });
        }
        if (error.response.status === 403) {
          message.info({
            content: 'Your account does not have permission to access to this page',
            duration: 3,
            onClose: () => {
              window.location.href = window.location.origin + '/property';
            },
          });
        }
        if (error.response.status === 412) {
          message.info({
            content: error.response.data.errors.detail,
            duration: 5,
          });
        }
        if (!this.refreshToken || error.response.status !== 401 || error.config.retry) {
          throw error;
        }
        window.location.href = window.location.origin + '/signin';
        // if (!this.refreshRequest) {
        //   this.refreshRequest = this.client.post(`${apiUrl}${REFRESH_TOKEN}`, {
        //     refresh_token: this.refreshToken,
        //     grant_type: "refresh_token",
        //   });
        // }

        // const { data } = await this.refreshRequest;
        // this.token = data.access_token;
        // this.refreshToken = data.refresh_token;
        // accountsStorage.setItem("token", data.access_token);
        // accountsStorage.setItem("refreshToken", data.refresh_token);
        // const newRequest = {
        //   ...error.config,
        //   retry: true,
        // };
        // return this.client(newRequest);
      },
    );
  }

  async loginApp() {
    const { data } = await this.client.post(
      `${apiUrl}${AUTHORIZE}`,
      qs.stringify(postData),
      headers,
    );
    token = data.access_token;
    // accountsStorage.setItem("token", data.access_token);
  }

  async loginUser(login: string, password: string) {
    try {
      const loginData = await this.client.post(
        `${apiUrl}${AUTH_OLD}`,
        {
          username: login,
          password,
        },
        {
          withCredentials: true,
        },
      );
      const { data } = await this.client.post(
        `${apiUrl}${AUTHORIZE}`,
        qs.stringify({
          ...postData,
          grant_type: 'password',
          username: login,
          password,
        }),
        { headers, withCredentials: true },
      );

      // accountsStorage.setItem("token", data.access_token);
      // accountsStorage.setItem("refreshToken", data.refresh_token);
      // accountsStorage.setItem("uuid", data.uuid);

      token = data.access_token;
      refreshToken = data.refresh_token;
      uuid = data.uuid;
      this.getAccountsData();
      return loginData;
    } catch (error) {
      return {
        type: 'error',
        error: error,
      };
    }
  }

  forgotPassword(email: string, username: string) {
    return this.client.get(`${apiUrl}${FORGOT_PASSWORD}`, {
      params: {
        emailOrLogin: email,
        ...(username && { username }),
      },
    });
  }

  resetPassword(password: string, confirmPassword: string) {
    return this.client.patch(
      `${apiUrl}/api/v1/actions/reset-password/${window.location.pathname.split('/')[3]}`,
      {
        password: password,
        confirm_password: confirmPassword,
      },
    );
  }

  getAccountsData() {
    return this.client.get(`${apiUrl}/api/v1/actions/current`, {
      withCredentials: true,
    });
  }

  getOrdersList(params?: any, filter?: any, searchValue?: string) {
    const search = searchValue
      ? {
          q: searchValue,
        }
      : {};
    return this.client.get(`${apiUrl}${ORDERS}`, {
      withCredentials: true,
      params: {
        ...params,
        ...filter,
        ...search,
      },
    });
  }

  getOrder(uuid: string) {
    return this.client.get(
      `${apiUrl}${ORDERS}/${uuid}?relations=orderType,states,status,listingStatus,m:mcs:state:login,osa:spa,wo:sp,hst_i,p,odf`,
      {
        withCredentials: true,
      },
    );
  }

  getPresentation(uuid: string) {
    return this.client.get(`${apiUrl}${ORDERS}/${uuid}/presentation `, {
      withCredentials: true,
    });
  }

  getHDOrder(uuid: string) {
    return this.client.get(
      `${apiUrl}${ORDERS}/${uuid}?relations=ohdp,states,m:mcs:state:login,ot`,
      {
        withCredentials: true,
      },
    );
  }

  updateHDOrder(uuid: string, data: any) {
    return this.client.patch(`${apiUrl}${ORDERS}/${uuid}?relations=ohdp:hdp`, data, {
      withCredentials: true,
    });
  }

  registrationAccounts(data: any) {
    return this.client.post(`${apiUrl}${ACCOUNTS}`, data, {
      withCredentials: true,
    });
  }

  getStates() {
    return this.client.get(`${apiUrl}${STATES}`);
  }

  uploadFile(type: string, uid: string, data: any) {
    if (!data) {
      return false;
    }
    return this.client.post(`${apiUrl}${ACCOUNTS}/${uid}/${type}`, data, {
      withCredentials: true,
    });
  }

  validateUnique(type: string, value: string) {
    return this.client.get(`${apiUrl}${VALIDATION}`, {
      params: {
        [type]: value,
      },
    });
  }

  validateUniqueWithInfo(type: string, value: string) {
    return this.client.get(`${apiUrl}${VALIDATION}`, {
      params: {
        [type]: value,
        returnInfo: 1,
      },
    });
  }

  getHotspots(uid: string) {
    return this.client.get(`${apiUrl}${ORDERS}/${uid}${HOTSPOTS}${window.location.search}`, {
      withCredentials: true,
    });
  }

  saveHotspots(uid: string, data: any) {
    return this.client.patch(
      `${apiUrl}${ORDERS}/${uid}${HOTSPOTS}${window.location.search}`,
      { ...data },
      {
        withCredentials: true,
      },
    );
  }

  getLogo() {
    const getQueryVariable = (variable: string) => {
      const query = window.location.search.substring(1);
      const vars = query.split('&');
      for (let i = 0; i < vars.length; i++) {
        const pair = vars[i].split('=');
        if (decodeURIComponent(pair[0]) == variable) {
          return decodeURIComponent(pair[1]);
        }
      }
    };
    if (!getQueryVariable('puuid')) {
      return false;
    }
    try {
      return this.client.get(`${apiUrl}/api/v1/actions/parent/${getQueryVariable('puuid')}`);
    } catch (error) {
      return false;
    }
  }

  getListingStatus() {
    return this.client.get(`${apiUrl}${LISTING_STATUS}`);
  }

  changeOrderData(key: string, uid: string, value: any) {
    // try {
    return this.client.patch(
      `${apiUrl}${ORDERS}/${uid}`,
      {
        [key]: value,
      },
      {
        withCredentials: true,
      },
    );
    // } catch (error) {}
  }

  changeOrderMultipleData(data: any, uid: string) {
    // try {
    return this.client.patch(
      `${apiUrl}${ORDERS}/${uid}?relations=orderType,states,listingStatus,status,m:mcs:state:login,osa:spa,wo:sp,p,odf`,
      data,
      {
        withCredentials: true,
      },
    );
    // } catch (error) {}
  }

  searchOrders(value: string, offset: number) {
    return this.client.get(
      `${apiUrl}${ORDERS}?q=${value}&relations=orderType,states,listingStatus,member&offset=${offset}&type[ne]=45,46`,
      {
        // type=45
        withCredentials: true,
      },
    );
  }

  getSubAccouts() {
    return this.client.get(`${apiUrl}${ACCOUNTS}?limit=10000`, {
      withCredentials: true,
    });
  }

  checkMls(memberId: string, mlsId: string) {
    return this.client.post(
      `${apiUrl}${CHECK_MLS}/${memberId}`,
      qs.stringify({
        mls_id: mlsId,
      }),
      {
        withCredentials: true,
      },
    );
  }

  getPlanCorection(uuid: string, puid?: string) {
    return this.client.get(`${apiUrl}${ORDERS}/${uuid}/corrections${puid ? puid : ''}`, {
      withCredentials: true,
    });
  }

  uploadPlanCorection(uuid: string, formData: any) {
    return this.client.post(`${apiUrl}${ORDERS}/${uuid}/corrections`, formData, {
      withCredentials: true,
    });
  }

  getWindermereUser(puuid: string) {
    return this.client.get(`${apiUrl}${PARENT}/${puuid}/windermere`, {
      withCredentials: true,
    });
  }

  getHelpersData(type: string) {
    return this.client.get(`${apiUrl}/api/v1/helpers/${type}`);
  }

  getAllAccounts(value: string) {
    return this.client.get(`${apiUrl}${ACCOUNTS}/all`, {
      params: { q: value },
      withCredentials: true,
    });
  }

  createAgent(data: any) {
    return this.client.post(`${apiUrl}/api/accounts/co-lister`, data, {
      withCredentials: true,
    });
  }

  getFeatures(uuid: string) {
    return this.client.get(`${apiUrl}${ORDERS}/${uuid}/features`, {
      withCredentials: true,
    });
  }

  setFeatures(uuid: string, data: any) {
    return this.client.post(`${apiUrl}${ORDERS}/${uuid}/features`, data, {
      withCredentials: true,
    });
  }

  getOrdersStats(id: string, type: string, period: string) {
    return this.client.get(
      `${apiUrl}/php5/piwik/?module=API&method=${type}&idSite=${id}&period=${period}&date=yesterday&format=JSON&token_auth=anonymous`,
      {
        withCredentials: true,
      },
    );
  }

  getOrderHistory(id: string) {
    return this.client.get(`${apiUrl}${ORDERS}/${id}/history`, {
      withCredentials: true,
    });
  }

  getAddons(orderId: any) {
    return this.client.get(`${apiUrl}/api/v1/helpers/addons?for_order_page=1&order_id=${orderId}`, {
      withCredentials: true,
    });
  }

  getPaymentTypes(uuid: string) {
    return this.client.get(`${apiUrl}${ORDERS}/${uuid}/renew/payment-types`, {
      withCredentials: true,
    });
  }

  saveRenew(uuid: string, data: any) {
    return this.client.post(`${apiUrl}${ORDERS}/${uuid}/renew`, data, {
      withCredentials: true,
    });
  }

  getMedia(uuid: string, type: string) {
    return this.client.get(`${apiUrl}${ORDERS}/${uuid}/media/${type}`, {
      withCredentials: true,
    });
  }

  downloadMedia(uuid: string, type: string, data: any) {
    return this.client.post(`${apiUrl}/api/v1/download/${uuid}/${type}`, data, {
      withCredentials: true,
    });
  }

  addMedia(uuid: string, data: any) {
    return this.client.post(`${apiUrl}${ORDERS}/${uuid}/media/add-media`, data, {
      withCredentials: true,
    });
  }

  updateMedia(uuid: string, data: any) {
    return this.client.patch(`${apiUrl}${ORDERS}/${uuid}/media/update-media`, data, {
      withCredentials: true,
    });
  }

  deleteMedia(uuid: string, data: any) {
    return this.client.delete(`${apiUrl}${ORDERS}/${uuid}/media/delete-media`, {
      data,
      withCredentials: true,
    });
  }

  deletePhoto(orderId: number, photoId: number) {
    return this.client.delete(`${apiUrl}/api/v1/video-editor/${orderId}/delete-image/${photoId}`, {
      withCredentials: true,
    });
  }

  uploadDocuments(uuid: string, data: any) {
    return this.client.post(`${apiUrl}/api/v1/upload/${uuid}`, data, {
      withCredentials: true,
    });
  }

  updateDocuments(uuid: string, data: any, method: string) {
    return this.client[method](`${apiUrl}/api/v1/upload/${uuid}`, data, {
      withCredentials: true,
    });
  }

  deleteDocuments(uuid: string, data: any, method: string) {
    return this.client[method](`${apiUrl}/api/v1/upload/${uuid}`, {
      data,
      withCredentials: true,
    });
  }

  getFloorPlanLevels(uuid: string, searchString: string) {
    return this.client.get(
      `${apiUrl}${ORDERS}/${uuid}/floorplans/levels-name?level_name=${searchString}`,
      {
        withCredentials: true,
      },
    );
  }

  uploadFloorPlans(uuid: string, data: any) {
    return this.client.post(`${apiUrl}${ORDERS}/${uuid}/floorplans/save-floorplan`, data, {
      withCredentials: true,
    });
  }

  deleteFloorPlans(uuid: string, data: any) {
    return this.client.delete(`${apiUrl}${ORDERS}/${uuid}/floorplans/delete-floorplan`, {
      data,
      withCredentials: true,
    });
  }

  setUploadId(uuid: string, data: any) {
    return this.client.patch(`${apiUrl}/api/v1/upload/${uuid}/sort`, data, {
      withCredentials: true,
    });
  }

  getDistrubution(uuid: string) {
    return this.client.get(`${apiUrl}${ORDERS}/${uuid}/distribution`, {
      withCredentials: true,
    });
  }

  setDistrubutionChecked(uuid: string, data: any) {
    return this.client.put(`${apiUrl}${ORDERS}/${uuid}/distribution`, data, {
      withCredentials: true,
    });
  }

  relinkDistribution(uuid: string, ps_id: number) {
    return this.client.get(`${apiUrl}/api/v1/orders/${uuid}/distribution/${ps_id}/relink`, {
      withCredentials: true,
    });
  }

  getMemberDistributions() {
    return this.client.get(`${apiUrl}/api/v1/member/distribution`, {
      withCredentials: true,
    });
  }

  getMemberDistributionsModal(uuid: string) {
    return this.client.get(`${apiUrl}/api/v1/orders/${uuid}/distribution/publish`, {
      withCredentials: true,
    });
  }

  getHDFloorplans(uuid: string) {
    return this.client.get(`${apiUrl}/api/v1/orders/${uuid}/floorplans`, {
      withCredentials: true,
    });
  }

  getInviteEmail(uuid: string) {
    return this.client.get(`${apiUrl}/api/v1/orders/${uuid}/hd/invite-email`, {
      withCredentials: true,
    });
  }

  sendInviteEmail(uuid: string, data: any) {
    return this.client.post(`${apiUrl}/api/v1/orders/${uuid}/hd/send-invite-email`, data, {
      withCredentials: true,
    });
  }

  setCheckMemberDistributions(systems: any, emails: [], uuid: string) {
    const data = {
      posting_systems: systems.map((item: number) => {
        return { ps_id: item };
      }),
      emails: emails
        .filter((item: string) => item)
        .map((item: string) => {
          return { email: item };
        }),
    };

    return this.client.post(`${apiUrl}/api/v1/orders/${uuid}/distribution/publish`, data, {
      withCredentials: true,
    });
  }

  setProduce(uuid: string) {
    return this.client.post(
      `${apiUrl}/api/v1/orders/${uuid}/produce`,
      {},
      {
        withCredentials: true,
      },
    );
  }

  setPublish(uuid: string) {
    return this.client.post(
      `${apiUrl}/api/v1/orders/${uuid}/publish`,
      {},
      {
        withCredentials: true,
      },
    );
  }

  getHomediaryPackages() {
    return this.client.get(`${apiUrl}/api/v1/helpers/hd-packages`, {
      withCredentials: true,
    });
  }

  getHDToken(uuid: string) {
    return this.client.get(`${apiUrl}${ACCOUNTS}/${uuid}/get-hd-auth`, {
      withCredentials: true,
    });
  }

  loginHDUser(uuid: string, data: any) {
    return this.client.post(`${apiUrl}${ACCOUNTS}/${uuid}/login-hd`, data, {
      withCredentials: true,
    });
  }

  registerHDUser(uuid: string, data: any) {
    return this.client.post(`${apiUrl}${ACCOUNTS}/${uuid}/register-hd`, data, {
      withCredentials: true,
    });
  }

  createHDProperty(data: any) {
    return this.client.post(`${apiUrl}${ORDERS}/hd`, data, {
      withCredentials: true,
    });
  }

  sendCreditCardData(data: any) {
    return this.client.post(`${apiUrl}${PAYMENTS}/auth`, data, {
      withCredentials: true,
    });
  }

  getQRCode(uuid: string) {
    return this.client.get(`${apiUrl}${ORDERS}/${uuid}/media/qr`, {
      withCredentials: true,
    });
  }

  logOut() {
    return this.client.get(`${apiUrl}/api/v1/actions/auth/logout`, {
      withCredentials: true,
    });
  }

  setOrderDisabled(uuid: string) {
    return this.client.post(
      `${apiUrl}${ORDERS}/${uuid}/disable`,
      {},
      {
        withCredentials: true,
      },
    );
  }

  reactivateOrder(uuid: string) {
    return this.client.patch(
      `${apiUrl}${ORDERS}/${uuid}/reactivate`,
      {},
      {
        withCredentials: true,
      },
    );
  }

  setOrderCanceled(uuid: string, reasonID) {
    return this.client.post(
      `${apiUrl}${ORDERS}/${uuid}/cancel`,
      { reason_id: reasonID },
      {
        withCredentials: true,
      },
    );
  }

  getCancelReasons() {
    return this.client.get(`${apiUrl}/api/v1/helpers/cancel-reasons`);
  }

  sendEmails(emails: {}, uuid: string) {
    return this.client.post(`${apiUrl}${ORDERS}/${uuid}/hd/share`, emails, {
      withCredentials: true,
    });
  }

  getTypesToStatuses() {
    return this.client.get(`${apiUrl}/api/v1/helpers/listing-types-to-listing-statuses`);
  }

  getPaymentInfo(data) {
    return this.client.get(`${apiUrl}/api/confirm/getPaymentInfo?member_id=${data.member_id}`, {
      withCredentials: true,
    });
  }

  getAgentProfile(uuid: string) {
    return this.client.get(`${apiUrl}${ACCOUNTS}/${uuid}`, {
      withCredentials: true,
    });
  }

  saveAgentProfile(uuid: string, data: any) {
    return this.client.patch(`${apiUrl}${ACCOUNTS}/${uuid}`, data, {
      withCredentials: true,
    });
  }

  changePassword(currentPassword: string, newPassword: string) {
    return this.client.patch(
      `${apiUrl}${ACCOUNTS}/change-password`,
      {
        old_password: currentPassword,
        new_password: newPassword,
      },
      {
        withCredentials: true,
      },
    );
  }

  getBrandKeeper() {
    return this.client.get(`${apiUrl}${ACCOUNTS}/brandkeeper`, {
      withCredentials: true,
    });
  }

  setBrandKeeper(data: any) {
    return this.client.post(`${apiUrl}${ACCOUNTS}/brandkeeper`, data, {
      withCredentials: true,
    });
  }

  setMemberDistributions(data: any) {
    return this.client.post(`${apiUrl}/api/v1/member/distribution`, data, {
      withCredentials: true,
    });
  }

  editEmail(data: any) {
    return this.client.patch(`${apiUrl}${ACCOUNTS}/change-email`, data, {
      withCredentials: true,
    });
  }

  getNotices() {
    return this.client.get(`${apiUrl}/api/v1/notices/user`, {
      withCredentials: true,
    });
  }

  setNoticesViewed(id: number) {
    return this.client.post(
      `${apiUrl}/api/v1/notices/view/${id}`,
      { optin_checkbox_checked: 1, dont_show_notice_again: 1 },
      {
        withCredentials: true,
      },
    );
  }

  resendMagicLink(uuid: string) {
    return this.client.post(`${apiUrl}${ORDERS}/${uuid}/magiclink-resend`, null, {
      withCredentials: true,
    });
  }

  createSampleTour() {
    return this.client.post(`${apiUrl}${ORDERS}/create-sample`, null, {
      withCredentials: true,
    });
  }

  getFeaturedListings(uuid: string, limit: number, offset: number, sort: string) {
    return this.client.get(
      `${apiUrl}${ACCOUNTS}/${uuid}/featured-listing?limit=${limit}&offset=${offset}&sort=${sort}`,
      {
        withCredentials: true,
      },
    );
  }

  setFeaturedListings(uuid: string, data: any) {
    return this.client.patch(`${apiUrl}${ACCOUNTS}/${uuid}/featured-listing`, data, {
      withCredentials: true,
    });
  }

  setEmailReporting(uuid: string, data: any) {
    return this.client.post(`${apiUrl}${ORDERS}/${uuid}/visit-stats`, data, {
      withCredentials: true,
    });
  }

  deleteEmailReporting(uuid: string) {
    return this.client.delete(`${apiUrl}${ORDERS}/${uuid}/visit-stats`, {
      withCredentials: true,
    });
  }

  deleteMemberPhoto(uuid: string) {
    return this.client.delete(`${apiUrl}${ACCOUNTS}/${uuid}/photo`, {
      withCredentials: true,
    });
  }
  deleteMemberLogo(uuid: string) {
    return this.client.delete(`${apiUrl}${ACCOUNTS}/${uuid}/logo`, {
      withCredentials: true,
    });
  }

  getStyles(uuid: string) {
    return this.client.get(`${apiUrl}${ORDERS}/${uuid}/presentation-customs`, {
      withCredentials: true,
    });
  }

  setStyles(uuid: string, data: any) {
    return this.client.patch(`${apiUrl}${ORDERS}/${uuid}/presentation-customs`, data, {
      withCredentials: true,
    });
  }
}
