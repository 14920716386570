import React from 'react';
import { Layout, PageHeader, Avatar, Button } from 'antd';

import logo from '../assets/img/logo.svg';
import styles from './Share.module.scss';
import { Link } from 'react-router-dom';
import { EditOutlined, UserOutlined } from '@ant-design/icons';

interface Props {
  address: string;
  mlsID: string;
  frontPhoto: string;
  auth: boolean;
  uuid: string;
}

const Header = (props: Props) => {
  const { address, mlsID, frontPhoto, auth, uuid } = props;
  return (
    <div style={{ backgroundColor: '#fff' }}>
      <Layout.Header className={styles.Header}>
        <div className={styles.Logo}>
          <img src={logo} alt="logo" />
        </div>
        <div>
          {!auth ? <Link to={`/signin`}><UserOutlined /> Log In</Link> : <Link to={`/property/${uuid}`}><EditOutlined /> Edit This Tour</Link>}
        </div>
      </Layout.Header>
      <div className="page-header">
        <PageHeader
          title={
            <div>
              <Avatar shape="square" src={frontPhoto} /> {address}
            </div>
          }
          subTitle={`MLS ID ${mlsID}`}
        />
      </div>
    </div>
  );
};

export default Header;
