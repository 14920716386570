import { DownloadOutlined } from '@ant-design/icons';
import { Button, Card } from 'antd';
import React, { useState } from 'react';

import RequestServices from '../helpers/requestServices';

const api = new RequestServices({});

export interface IFloorPlanProps {
  uuid: string;
  plans: any[];
}

function downloadFile(filePath) {
  const link = document.createElement('a');
  link.href = filePath;
  link.download = filePath.substr(filePath.lastIndexOf('/') + 1);
  link.click();
}

export function FloorPlan(props: IFloorPlanProps) {
  const [loading, setLoading] = useState(false);
  const download = async (type: string) => {
    setLoading(true);
    const res = await api.downloadMedia(props.uuid, type, []);
    setLoading(false);
    downloadFile(res.data.data.file);
  };
  const extra = (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Button
        onClick={() => download('floorplan')}
        loading={loading}
        icon={<DownloadOutlined />}
        style={{ margin: '0 10px' }}
      >
        Download
      </Button>
    </div>
  );
  return (
    <Card id="plans" title="Floor plan" style={{ marginBottom: '20px' }} extra={extra}>
      <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr' }}>
        {props.plans.map((el: any) => {
          return (
            <Card
              key={el.level_name}
              // hoverable={true}
              style={{ width: 270, marginBottom: 15 }}
              // cover={<img src={el.image_url} />}
            >
              <div>
                <img alt={`${el.level_name} floorplan`} src={el.image_url} />
              </div>
              {el.level_name}
            </Card>
          );
        })}
      </div>
    </Card>
  );
}
