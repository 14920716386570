const STORAGE_KEY = "stepData";

export default class StepData {
  private storage: any;
  private key: string;
  constructor(key: string) {
    this.storage = window.localStorage;
    this.key = `${STORAGE_KEY}_${key}`;
  }

  get() {
    const data = this.storage.getItem(this.key);

    try {
      return JSON.parse(data);
    } catch (e) {
      console.log("stepData is wrong");
      return [];
    }
  }

  add(data: any) {
    this.storage.setItem(
      this.key,
      JSON.stringify(data)
    );
  }

  remove() {
    this.storage.removeItem(this.key);
  }
}
