import * as React from "react";
import { Steps } from "antd";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import * as actions from "./actions";
import { Route, Switch, Router } from "react-router";
import { IStoreState } from "../../storeStateTypes";
import ReactGA from "react-ga";

import RequestServices from "../../helpers/requestServices";

import styles from "./Registration.module.scss";

import logo from "../../assets/img/hdProLogoVectorDark.svg";

const StepOneComponent = React.lazy(() => import("./StepOne/StepOneComponent"));

const StepsTwoComponent = React.lazy(() =>
  import("./StepTwo/StepTwoComponent")
);

const StepsThreeComponent = React.lazy(() =>
  import("./StepThree/StepThreeComponent")
);

const api = new RequestServices({});

export interface IRegistrationProps {
  profileProperty: any;
  history: any;
  getInputValue: (value: object) => void;
  getWindermereUser: (puuid: string) => void;
}

export interface IRegistrationState {
  stepsKey: number;
  subLogo: string;
  waitLogo: boolean;
}

class RegistrationContainer extends React.Component<
  IRegistrationProps,
  IRegistrationState
> {
  constructor(props: IRegistrationProps) {
    super(props);

    this.state = {
      stepsKey: 0,
      subLogo: "",
      waitLogo: true,
    };
  }

  componentDidMount() {
    this.getLogo();
    ReactGA.initialize("UA-798212-3");
  }

  getLogo = async () => {
    try {
      const data: any = await api.getLogo();
      if (!data) {
        this.setState({
          waitLogo: false,
        });
        return;
      }
      this.setState({
        subLogo: data.data.data.logo,
        waitLogo: false,
      });
    } catch (error) {
      this.setState({
        waitLogo: false,
      });
    }
  };

  changeStepsKey = (key: number) => {
    this.setState({
      stepsKey: key,
    });
  };

  getInputsValues = (values: object) => {
    this.props.getInputValue(values);
  };
  public render() {
    if (this.state.waitLogo) {
      return null;
    }
    const { stepsKey } = this.state;
    const { Step } = Steps;

    const stepsDescription: any = [
      {
        title: "Your Account",
        description: "Primary contact and login information.",
      },
      {
        title: "Your Profile",
        description:
          "Contact information you want to display on your tours. You can add or edit this later.",
      },
      {
        title: "Confirmation",
        description: "You are ready for your first order!",
      },
    ];
    const switchRegistrationComponent = (key: number) => {
      let StepsComponent: any;
      switch (key) {
        case 0:
          StepsComponent = React.lazy(() =>
            import("./StepOne/StepOneComponent")
          );
          break;
        case 1:
          StepsComponent = React.lazy(() =>
            import("./StepTwo/StepTwoComponent")
          );
          break;
        case 2:
          StepsComponent = React.lazy(() =>
            import("./StepThree/StepThreeComponent")
          );
          break;
        default:
          StepsComponent = React.lazy(() =>
            import("./StepOne/StepOneComponent")
          );
          break;
      }
      return (
        <StepsComponent
          changeStepsKey={this.changeStepsKey}
          // getInputsValues={this.getInputsValues}
        />
      );
    };
    return (
      <div className={styles.Wrapper}>
        <div className={styles.Header}>
          {this.state.subLogo ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: 20,
              }}
            >
              <div style={{ maxWidth: 275 }}>
                <img src={this.state.subLogo} alt="" />
              </div>
              <span style={{ margin: "0 20px" }}>powered by</span>
              <div className={styles.Logo}>
                <img src={logo} alt="logo" />
              </div>
            </div>
          ) : (
            <div className={styles.Logo}>
              <img src={logo} alt="logo" />
            </div>
          )}
          <div className={styles.Description}>
            <h1 className="page-title">{stepsDescription[stepsKey].title}</h1>
            <p>{stepsDescription[stepsKey].description}</p>
          </div>
        </div>
        <div className={styles.InputsWrapper}>
          <Steps current={stepsKey} className={styles.Steps}>
            <Step title="Account" />
            <Step title="Profile" />
            <Step title="Confirmation" />
          </Steps>
          {/* {switchRegistrationComponent(stepsKey)} */}
          <Switch>
            <Route
              path="/registration"
              exact={true}
              component={() => (
                <StepOneComponent
                  history={this.props.history}
                  changeStepsKey={this.changeStepsKey}
                />
              )}
            />
            <Route
              path="/registration/profile"
              component={() => (
                <StepsTwoComponent
                  history={this.props.history}
                  changeStepsKey={this.changeStepsKey}
                />
              )}
            />
            <Route
              path="/registration/confirmation"
              component={StepsThreeComponent}
            />
          </Switch>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: IStoreState) => {
  return {};
};

const mapDispatchToProps = (
  dispatch: Dispatch<actions.RegistrationActions>
) => {
  return {
    // getInputValue: (value: any) => dispatch(actions.getInputValue(value)),
    // getWindermereUser: async(puuid: string) => dispatch(await actions.getWindermereUser(puuid))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegistrationContainer);
