import React, { useState, useRef, useEffect } from "react";
import { Slider } from "antd";
import { Stage, Layer, Image } from "react-konva";
import {
  ZoomInOutlined,
  ZoomOutOutlined,
  ConsoleSqlOutlined,
} from "@ant-design/icons";

import styles from "./Hotspots.module.scss";
import { Vector2d } from "konva/types/types";

interface Props {
  plan?: any;
  iconImg: any;
  iconImgActive: any;
  dropIcon: any;
  clientWidth: number;
  clientHeight: number;
  markers: any;
  activeSpots: number | undefined;
  //   scaleProps: any;
  editIcon: (index: number, position: any) => void;
  setActiveSpots: (id: number) => void;
}

const CanvasComponent = ({
  plan,
  dropIcon,
  clientHeight,
  clientWidth,
  markers,
  iconImg,
  iconImgActive,
  activeSpots,
  setActiveSpots,
  editIcon,
}: Props) => {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [scale, setScale] = useState({ x: 0.8, y: 0.8 });
  const [dragIcon, setDragIcon] = useState(false);
  const [dragImage, setDragImage] = useState(true);
  const stage = useRef(null) as any;
  useEffect(() => {
    setPositionAndScale();
  }, []);

  const setPositionAndScale = () => {
    const initScale =
      clientHeight - 115 - plan.height > 0
        ? (clientHeight - 115 - plan.height) / (clientHeight - 115) + 1
        : 1 - (plan.height - (clientHeight - 115)) / plan.height;
    setPosition({
      x: (clientWidth - 360) / 2 - (plan.width / 2) * initScale,
      y: (clientHeight - 115) / 2 - (plan.height / 2) * initScale,
    });

    setScale({
      x: initScale,
      y: initScale,
    });
  };

  const onScaleSlider = (e: any) => {
    const mousePointTo = {
      x: Math.floor(
        (position.x + (plan.width / 2) * scale.x - position.x) / scale.x
      ),
      y: Math.floor(
        (position.y + (plan.height / 2) * scale.x - position.y) / scale.y
      ),
    };
    setPosition({
      x: Math.floor(
        position.x + (plan.width / 2) * scale.x - mousePointTo.x * e
      ),
      y: Math.floor(
        position.y + (plan.height / 2) * scale.x - mousePointTo.y * e
      ),
    });
    setScale({ x: e, y: e });
  };
  const onScale = (event: any) => {
    event.evt.preventDefault();
    const scaleBy = 1.1;
    const pointer = stage.current.getPointerPosition();
    const mousePointTo = {
      x: (pointer.x - position.x) / scale.x,
      y: (pointer.y - position.y) / scale.y,
    };
    const newScale =
      event.evt.deltaY < 0 ? scale.x * scaleBy : scale.x / scaleBy;

    if (scale.x > 0.1) {
      setScale({
        x: newScale,
        y: newScale,
      });
      setPosition({
        x: pointer.x - mousePointTo.x * newScale,
        y: pointer.y - mousePointTo.y * newScale,
      });
    } else {
      if (event.evt.deltaY < 0) {
        setScale({
          x: newScale,
          y: newScale,
        });
        setPosition({
          x: pointer.x - mousePointTo.x * newScale,
          y: pointer.y - mousePointTo.y * newScale,
        });
      }
    }
  };

  const dragBoundFunc = (pos: Vector2d) => {
    const newY =
      pos.y + (plan.height / 2) * scale.x < 0
        ? -(plan.height / 2) * scale.x
        : pos.y + (plan.height / 2) * scale.x > clientHeight - 115
        ? clientHeight - 115 - (plan.height / 2) * scale.x
        : pos.y;
    const newX =
      pos.x + (plan.width / 2) * scale.x < 0
        ? -(plan.width / 2) * scale.x
        : pos.x + (plan.width / 2) * scale.x > clientWidth - 360
        ? clientWidth - 360 - (plan.width / 2) * scale.x
        : pos.x;

    return {
      x: newX,
      y: newY,
    };
  };

  return (
    <div style={{ position: "relative" }}>
      <div
        onDrop={(event) => dropIcon(event, stage.current, position, scale)}
        onDragOver={(e) => e.preventDefault()}
        style={{ marginRight: 10 }}
      >
        <Stage
          width={clientWidth - 360}
          height={clientHeight - 115}
          style={{ backgroundColor: "#F7F8FA" }}
          ref={stage}
          onWheel={(event: any) => onScale(event)}
        >
          <Layer
            scale={scale}
            dragBoundFunc={(pos: Vector2d) => dragBoundFunc(pos)}
            onDragEnd={(e) =>
              dragImage &&
              setPosition({
                x: e.target.x(),
                y: e.target.y(),
              })
            }
            x={position.x}
            y={position.y}
            draggable={dragImage}
          >
            <Image image={plan} />
            {markers.map((image: any, i: number) => {
              if (image.hs_id === 0) {
                return;
              }
              return (
                <Image
                  key={i}
                  onDragEnd={(e) =>
                    editIcon(i, { x: e.target.x(), y: e.target.y() })
                  }
                  scale={scale.x <= 1 ? {x: 1 / scale.x, y: 1 / scale.x } : {x: scale.x / (scale.x * 1) , y: scale.x / (scale.x * 1)  }}
                  onDragStart={() => setDragImage(false)}
                  onClick={() => setActiveSpots(image.hsi_id)}
                  draggable={true}
                  image={activeSpots === image.hsi_id ? iconImgActive : iconImg}
                  x={image.coord_x}
                  y={image.coord_y}
                  onMouseEnter={() => {
                    document.body.style.cursor = "pointer";
                    setDragImage(false);
                  }}
                  onMouseLeave={() => {
                    document.body.style.cursor = "default";
                    setDragImage(true);
                  }}
                  offsetX={iconImg ? iconImg.width / 2 : 0}
                  offsetY={iconImg ? iconImg.height / 2 : 0}
                />
              );
            })}
          </Layer>
        </Stage>
      </div>
      <div className={styles.Zoom}>
        <ZoomOutOutlined style={{ marginRight: 10 }} />
        <Slider
          min={0.1}
          max={2}
          tooltipVisible={false}
          step={0.1}
          value={scale.x}
          onChange={(e: any) => onScaleSlider(e)}
          style={{ width: 200 }}
        />
        <ZoomInOutlined style={{ marginLeft: 10 }} />
      </div>
    </div>
  );
};

export default CanvasComponent;
