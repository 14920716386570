import { CopyOutlined, DownloadOutlined } from '@ant-design/icons';
import { Button, Card, message } from 'antd';
import React, { useState } from 'react';
import { MESSAGE_POSITION } from '../constants/message';
import IframeModal from '../helpers/Components/IframeModal';

export interface IWalkthroughVideoProps {
  video: any[];
  id: string;
}

export function WalkthroughVideo(props: IWalkthroughVideoProps) {
  const [viewModal, setViewModal] = useState({
    visible: false,
    el: '',
    name: '',
  });

  const onCopyLink = (link: string) => {
    navigator.clipboard.writeText(link);
    message.success({
      content: 'Copied to clipboard',
      style: MESSAGE_POSITION,
    });
  };

  const extra = (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Button onClick={() => onCopyLink(props.video[0])} style={{ margin: '0 10px' }}>
        <CopyOutlined /> Copy link
      </Button>
      {/* <Button style={{ marginRight: "10px"  }}><ShareAltOutlined /> Share</Button> */}
      <Button style={{ marginRight: '10px' }}>
        <a href={props.video[0]} download>
          <DownloadOutlined /> Download
        </a>
      </Button>
    </div>
  );
  return (
    <Card id="walkVideo" title="Slideshow Video" style={{ marginBottom: '20px' }} extra={extra}>
      <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr' }}>
        {props.video.map((el: any) => {
          return (
            <Card
              key={el.video}
              // hoverable={true}
              style={{ width: 270 }}
              cover={
                <div style={{ position: 'relative' }}>
                  <video style={{ width: '100%' }} controls={true}>
                    <source src={el} type="video/mp4" />{' '}
                  </video>
                  <div
                    onClick={(e) => {
                      setViewModal({
                        visible: true,
                        el,
                        name: el.title,
                      });
                    }}
                    style={{
                      position: 'absolute',
                      width: '100%',
                      height: '100%',
                      top: 0,
                      left: 0,
                      cursor: 'pointer',
                    }}
                  />
                </div>
              }
            >
              <IframeModal
                changeVisible={() => setViewModal({ ...viewModal, visible: false })}
                isModalVisible={viewModal.visible}
                iframeLink={viewModal.el}
              />
              Slideshow Video
            </Card>
          );
        })}
      </div>
    </Card>
  );
}
