export const GET_ORDER_DATA = 'GET_ORDER_DATA';
export type GET_ORDER_DATA = typeof GET_ORDER_DATA;

export const CHANGE_MAP_DATA = 'CHANGE_MAP_DATA';
export type CHANGE_MAP_DATA = typeof CHANGE_MAP_DATA;

export const CHANGE_APPOINTMENT_DATA = 'CHANGE_APPOINTMENT_DATA';
export type CHANGE_APPOINTMENT_DATA = typeof CHANGE_APPOINTMENT_DATA;

export const DELETE_ORDER_DATA = 'DELETE_ORDER_DATA';
export type DELETE_ORDER_DATA = typeof DELETE_ORDER_DATA;

export const UPDATE_MEDIA = 'UPDATE_MEDIA';
export type UPDATE_MEDIA = typeof UPDATE_MEDIA;

export const LOADING = 'LOADING';
export type LOADING = typeof LOADING;

export const CHANGE_STATUS = 'CHANGE_STATUS';
export type CHANGE_STATUS = typeof CHANGE_STATUS;

export const SET_PRESENTATION_DATA = 'SET_PRESENTATION_DATA';
export type SET_PRESENTATION_DATA = typeof SET_PRESENTATION_DATA;