import { connect } from 'react-redux';
import AppComponent from './AppComponent'
import { getAccountsData } from './actions'
import { DispatchType } from './types'

const mapStateToProps = (state) => {
    return {
        uuid: state.app.uuid,
        redirectOld: state.app.redirectOld
    }
}

const mapDispatchToProps = (dispatch: DispatchType) => {
    return {
        getAccountsData: getAccountsData(dispatch)
    }
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(AppComponent)
