import { Button, Modal } from "antd";
import  React from "react";
import {
  CloseOutlined,
} from "@ant-design/icons";

export interface IIframeModalProps {
 
  iframeLink: any;
  isModalVisible: boolean;
  changeVisible: (value: boolean) => void;
}

export default function IframeModal(props: IIframeModalProps) {
  const { iframeLink, isModalVisible, changeVisible } = props;
  return (
    <Modal
      title={null}
      visible={isModalVisible}
      footer={null}
      width={'100vw'}
      destroyOnClose={true}
      onCancel={() => changeVisible(false)}
      closeIcon={<Button icon={<CloseOutlined/>}/>}
      style={{ top: 20 }}
    >
      <iframe
        allowFullScreen={true}
        data-hj-allow-iframe
        style={{height: 'calc(100vh - 100px)', border: 'none'}}
        width="100%"
        height="100%"
        scrolling="auto"
        src={iframeLink}
      />
    </Modal>
  );
}
