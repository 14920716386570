import {
  CHANGE_MAP_DATA,
  GET_ORDER_DATA,
  DELETE_ORDER_DATA,
  UPDATE_MEDIA,
  LOADING,
  CHANGE_APPOINTMENT_DATA,
  CHANGE_STATUS,
  SET_PRESENTATION_DATA,
} from "./constans";

const initialState = {
  data: {},
  presentation: {},
  update: true,
  mapData: {},
  loading: true
};

export function PropertyReducer(state: any = initialState, action: any) {
  switch (action.type) {
    case GET_ORDER_DATA:
      return {
        ...state,
        data: {
          id: action.payload.mls_id,
          city: action.payload.city,
          street: action.payload.street,
          state: action.payload.states.short_name,
          statusName: action.payload.status.name,
          sta_id: action.payload.status.sta_id,
          quickLinks: action.payload.availableActions.quickLinks,
          image: action.payload.front_photo.file,
          mlsState: action.payload.mls_state,
          listing_type_id: action.payload.listing_type_id,
          property_type_id: action.payload.property_type_id,
          lsta_id: action.payload.lsta_id,
          state_id: action.payload.state_id,
          zip: action.payload.zip,
          map_lat: action.payload.map_lat || 0,
          map_lon: action.payload.map_lon || 0,
          lot_size: action.payload.lot_size, // ??
          num_of_floors_above_ground: action.payload.num_of_floors_above_ground,
          sq_footage: action.payload.sq_footage,
          listing_price_range_present: action.payload.listing_price_range_present,
          listing_price_range_from: action.payload.listing_price_range_from,
          listing_price_range_to: action.payload.listing_price_range_to,
          listing_price: action.payload.listing_price,
          show_on_featured_listings_page: action.payload.show_on_featured_listings_page,
          show_sq_footage_on_tour: action.payload.show_sq_footage_on_tour,
          livable_sq_footage_range_present:
            action.payload.livable_sq_footage_range_present,
          livable_sq_footage_range_from:
            action.payload.livable_sq_footage_range_from,
          livable_sq_footage_range_to:
            action.payload.livable_sq_footage_range_to,
          livable_sq_footage: action.payload.livable_sq_footage,
          seller: action.payload.seller,
          num_of_two_thirds_baths: action.payload.num_of_two_thirds_baths,
          num_of_quarter_baths: action.payload.num_of_quarter_baths,
          num_of_half_baths: action.payload.num_of_half_baths,
          num_of_baths: action.payload.num_of_baths,
          description: action.payload.description,
          descriptionUnbranded: action.payload.description_unbranded,
          map_type: action.payload.map_type,
          map_zoom_level: action.payload.map_zoom_level,
          tour_status: action.payload.tour_status,
          access_instructions: action.payload.access_instructions,
          appointment_date: action.payload.appointment_date,
          appointment_date_provider: action.payload.wo ? action.payload.wo.appointment_date : null,
          occupancy_status: action.payload.occupancy_status,
          providerData: action.payload.wo ? action.payload.wo.sp : null ,
          time_request: action.payload.time_request,
          gate_code: action.payload.gate_code,
          spAddons: action.payload.osa,
          show_listing_status: action.payload.show_listing_status,
          addons: action.payload.orderedAddons,
          estimatedPrice: action.payload.estimated_price,
          totalAmount: action.payload.total_amount,
          firstDraftDate: action.payload.first_draft_date,
          orderId: action.payload.order_id,
          uuid: action.payload.uuid_text,
          customStatus: action.payload.custom_listing_status,
          addGarageBays: action.payload.num_of_add_garage_bays,
          garageBays: action.payload.num_of_garage_bays,
          addGarageBaysType: action.payload.add_garage_bays_type,
          garageBaysType: action.payload.garage_bays_type,
          comments: action.payload.comments,
          keyLocation: action.payload.key_location,
          num_of_bedrooms: action.payload.num_of_bedrooms,
          total_baths: action.payload.total_baths,
          availableMediaContent: action.payload.availableMediaContent,
          createDate: action.payload.cdate,
          expires: action.payload.expires,
          colisting_member_id: action.payload.colisting_member_id,
          colisting_member_name: action.payload.colisting_member_name,
          presentation: action.payload.p,
          tourTitle: action.payload.tour_title,
          odf: action.payload.odf,
          orderType: action.payload.orderType,
          pricePeriod: action.payload.listing_price_period,
          pricePeriodFrom: action.payload.listing_price_period_from,
          pricePeriodTo: action.payload.listing_price_period_to,
          availableActions: action.payload.availableActions,
          availableSections: action.payload.availableSections,
          fdDate: action.payload.first_draft_date,
          calculateTotalBaths: action.payload.calculate_total_baths,
          yearBuilt: action.payload.year_built,
          options: action.payload.options,
          tourStatusChange: action.payload.availableActions.tourStatusChange,
          edate: action.payload.edate,
          frontPhoto: action.payload.front_photo.file,
          shareLink: action.payload.share_link,
          listingStatus: action.payload.listingStatus,
          is360: action.payload.is_360,
          type: action.payload.type,
          reportingEmail: action.payload.p.email_reporting_page_to,
          reportingPeriod: action.payload.p.email_reporting_page_periodicity,
          altContactName: action.payload.alt_contact_name,
          altEmail: action.payload.alt_email,
          altContactPhone: action.payload.alt_contact_phone,
        },
        mapData: {
          map_lat: action.payload.map_lat || 0,
          map_lon: action.payload.map_lon || 0,
          map_type: action.payload.map_type,
          map_zoom_level: action.payload.map_zoom_level,
        },
      };
    case SET_PRESENTATION_DATA:
      return {
        ...state,
        presentation: {
          photos: action.payload?.uploads,
          availableDashboards: action.payload.availableDashboards,
          dashboard: action.payload.dashboard,
        }
      };
    case CHANGE_MAP_DATA:
      return {
        ...state,
        mapData: {
          map_lat: action.payload.lat,
          map_lon: action.payload.lon,
          map_type: action.payload.type,
          map_zoom_level: action.payload.zoom
        },
      };

    case CHANGE_APPOINTMENT_DATA:
      return {
        ...state,
        data: {
          ...state.data,
          time_request: action.payload.time_request,
          gate_code: action.payload.gate_code,
          access_instructions: action.payload.access_instructions,
          keyLocation: action.payload.key_location,
          comments: action.payload.comments,
          occupancy_status: action.payload.occupancy_status,
        }
      };

    case DELETE_ORDER_DATA:
      return {
        ...state,
        data: {}
      }

    case UPDATE_MEDIA: {
      return {
        ...state,
        update: !state.update
      }
    }

    case CHANGE_STATUS: {
      return {
        ...state,
        data: {
          ...state.data,
          tour_status: action.payload.tour_status,
          availableSections: action.payload.availableSections
        }
      }
    }

    case LOADING:
      return {
        ...state,
        loading: action.payload
      }
    default:
      break;
  }
  return state;
}
