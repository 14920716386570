import * as React from 'react';
import { Spin } from 'antd'

export default function Loading () {
  return (
    <div className='spin'>
      <Spin />
    </div>
  );
}
