import { RegistrationActions } from "./actions";
import { IStoreState } from "../../storeStateTypes";
import {
  GET_INPUT_VALUE,
  CHANGE_INPUT_VALUE,
  ON_BLUR_INPUT,
  GET_PREVIEW_SRC,
  CHANGE_MODAL_VISIBLE,
  GET_WINDERMERE_USER,
} from "./constants";

export interface IRegistrationStoreState {
  profileProperty?:
    | {
        firstname: string;
        lastname: string;
        displayName: string;
        displayNameCheck: boolean;
        title?: string;
        titleCheck: boolean;
        designations?: string;
        designationsCheck: boolean;
        licenseNumber?: string;
        licenseCheck: boolean;
        photo?: string;
        buisnessName: string;
        buisnessNameCheck: boolean;
        buisnessAddres: string;
        buisnessAddressCheck: boolean;
        city: string;
        zip: string;
        zipCheck: boolean;
        logo?: string;
        email: string;
        emailCheck: boolean;
        directPhone: string;
        directPhoneCheck: boolean;
        mobilePhone?: string;
        mobilePhoneCheck: boolean;
        buisnessPhone: string;
        buisnessPhoneCheck: boolean;
        website: string;
        websiteCheck: boolean;
        windermereLoad: boolean;
      }
    | any;
  preview?: {
    photo?: any;
    logo?: any;
  };
  modalVisible: boolean;
}

const initialState = {
  profileProperty: {
    firstname: "",
    lastname: "",
    displayName: "",
    displayNameCheck: true,
    title: "",
    titleCheck: false,
    designations: "",
    designationsCheck: false,
    licenseNumber: "",
    licenseCheck: false,
    photo: "",
    buisnessName: "",
    buisnessNameCheck: false,
    buisnessAddress: "",
    buisnessAddressCheck: false,
    city: "",
    stateCheck: false,
    zip: "",
    zipCheck: false,
    logo: "",
    email: "",
    emailCheck: true,
    directPhone: "",
    directPhoneCheck: false,
    mobilePhone: "",
    mobilePhoneCheck: false,
    buisnessPhone: "",
    buisnessPhoneCheck: false,
    website: "",
    websiteCheck: false,
    windermereLoad: false,
  },
  preview: {
    photo: "",
    logo: "",
  },
  modalVisible: false,
};

export function registrationReducer(
  state: IRegistrationStoreState = initialState,
  action: RegistrationActions
): IRegistrationStoreState {
  switch (action.type) {
    case GET_INPUT_VALUE:
      return {
        ...state,
        profileProperty: {
          ...state.profileProperty,
          ...(action.payload as {}),
        },
      };
    case CHANGE_INPUT_VALUE:
      return {
        ...state,
        profileProperty: {
          ...state.profileProperty,
          ...action.payload,
        },
      };
    case ON_BLUR_INPUT:
      return {
        ...state,
        profileProperty: {
          ...state.profileProperty,
          [action.payload.name]: state.profileProperty[action.payload.inputName]
            ? true
            : false,
        },
      };
    case GET_PREVIEW_SRC:
      return {
        ...state,
        preview: {
          ...state.preview,
          [action.payload.name]: action.payload.src,
        },
      };
    case CHANGE_MODAL_VISIBLE: {
      return {
        ...state,
        modalVisible: !state.modalVisible,
      };
    }
    case GET_WINDERMERE_USER: {
      return {
        ...state,
        profileProperty: {
          ...state.profileProperty,
          ...action.payload,
        }
      };
    }
    default:
      break;
  }

  return state;
}
